import { useSelector } from "react-redux";

const PausedBanner = (props) => {
  const { backClick, currentTime } = props;
  const videoReducer = useSelector((state) => state.videoReducer);
  const toHHMMSS = (time) => {
    if (!time) {
      return 0;
    }
    let sec_num = parseInt(time, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;
    let str = "";
    if (hours > 0) {
      str = hours + "hr ";
    }
    if (minutes < 10) {
      str += minutes + "min ";
    }
    if (seconds < 10) {
      str += seconds + "sec";
    }
    return str;
  };
  return (
    <div
      className="pause-banner-container"
      style={{
        backgroundImage: `url('${videoReducer?.["landscape_1920x1080"]}')`,
      }}
    >
      <div className="paused-header">
        <span className="back-btn" onClick={backClick}></span>
      </div>
      <div className="paused-content">
        <p className="line1">You’re Watching</p>
        <p>
          <span className="video-title">{videoReducer?.video_title}</span>{" "}
          {videoReducer?.episode_number ? (
            <span className="season-episode">
              Se {videoReducer?.season_number} - Ep{" "}
              {videoReducer?.episode_number}
            </span>
          ) : null}
        </p>
        <p className="remaining-time">
          Remaining Time:{" "}
          {toHHMMSS(videoReducer?.total_time - currentTime) || 0}
        </p>
        <p className="description">{videoReducer?.short_description}</p>
      </div>
      <div className="paused-footer">
        <button onClick={backClick}>Resume</button>
      </div>
    </div>
  );
};

export default PausedBanner;
